import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../../components/SEO';
import ContentsList from '../../../components/ContentsList';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="アニメーション - スタイル" mdxType="SEO" />
    <PageTitle title="アニメーション" enTitle="Animation" mdxType="PageTitle" />
    <p>{`アニメーションを定義することで、UIに連続的な動きと物理的な加速度を反映します。UI内の要素の動きに対してこれらを反映することで現実世界との距離を縮め、ユーザーに対して親しみやすさや、よりよい操作感を提供できます。`}</p>
    {
      /* textlint-disable @openameba/ameba/no-synonyms */
    }
    <ContentsList list={[{
      url: "/styles/animation/property/",
      title: "プロパティ",
      enTitle: "Property",
      summary: "EasingとDurationの変数のパターンを定義して、それの組合わせをAnimation Typeとしてパッケージングして、それをComponent側に代入し利用します。"
    }, {
      url: "/styles/animation/type/",
      title: "種類",
      enTitle: "Animation Type",
      summary: "Animation Tokenのバリエーションと、どの動きに対してどのアニメーションを適用するかの一覧です。"
    }]} mdxType="ContentsList"></ContentsList>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      